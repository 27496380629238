<script lang="ts" setup>
const { footerOptions } = useMenu()
</script>

<template>
  <div class="relative h-screen bg-gray-100 overflow-hidden">
    <div class="absolute left-1/2 translate-x-[-50%] xl:left-0 xl:translate-x-0 top-0 p-5">
      <TheLogo :show-text="false" />
    </div>

    <div class="flex flex-col h-full">
      <div class="grid grid-cols-1 xl:grid-cols-5 gap-4 h-full items-center justify-center py-5">
        <div class="xl:col-span-3 w-full px-4 lg:px-0">
          <slot />
        </div>
        <div class="xl:flex flex-col hidden col-span-2 h-full">
          <img src="/images/hero.jpg" class="rounded h-full object-cover object-left mr-5">
        </div>
      </div>

      <div class="flex flex-col-reverse w-full bg-white border p-1.5 pb-1 md:flex-row md:items-center">
        <div class="flex-grow">
          <n-menu
            :options="footerOptions"
            mode="horizontal"
            class="text-[13px] justify-center md:justify-start md:text-sm"
            :render-icon="() => null"
          />
        </div>
        <div class="flex-shrink-0 md:w-[150px] px-2">
          <TheLanguageSelector class="w-full py-1" />
        </div>
      </div>
    </div>
  </div>
</template>
